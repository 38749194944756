import * as React from 'react'
import { AssociateImages } from '../components/Associates'
import { DecideProfit } from '../components/using/DecideProfit'
import { EnoughEnough } from '../components/using/EnoughEnough'
import { withLayout } from '../components/Layout'
import { NeedHelp } from '../components/using/NeedHelp'
import { PayingTooMuch } from '../components/using/PayingTooMuch'
import { UsingChoiceFree } from '../components/using/UsingChoiceFree'
import { Container } from '../components/base'
import { JoinMovement } from '../components/using/JoinMovement'

const UsingPage = () => (
  <Container>
    <NeedHelp />
    <UsingChoiceFree />
    <PayingTooMuch />
    <DecideProfit />
    <EnoughEnough />
    <JoinMovement />
    <AssociateImages />
  </Container>
)
export default withLayout(UsingPage)
