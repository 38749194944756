import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  Container,
  HalfWidthSmallSubtitle,
  SectionBox,
  SmallSubtitle,
  Subtitle,
  SectionHeader,
} from '../base'

export function PayingTooMuch(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceCredit: file(
        relativePath: { eq: "images/UsingChoiceCredit.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      UsingChoiceContactlessDebit: file(
        relativePath: { eq: "images/UsingChoiceContactlessDebit.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionBox bg="altSectionBackground">
      <Box fontFamily="body">
        <SectionHeader color="darkMuted">
          Kiwis are paying too much.
          <Text sx={{ display: 'inline-block' }} color="primary.main">
            $461 million in 2015
            <Text sx={{ display: 'inline-block' }} color="darkMuted">
              , to be precise!
            </Text>
          </Text>
        </SectionHeader>

        <Flex px={[0, 1, 0]} flexDirection="row" flexWrap="wrap">
          <Container width={[1, 1 / 2, 1 / 2]} py={[2, 2, 3]}>
            <HalfWidthSmallSubtitle>
              Every time you pay with your card at stores, our kiwi businesses
              are charged a fee.
            </HalfWidthSmallSubtitle>
            <Text
              fontSize={[1, 2, 1]}
              pb={[2, 0, 0]}
              color="muted"
              fontWeight="normal"
            >
              Most fees are percentage based for the average Kiwi business.
              Larger merchants can leverage lower fees or even small, fixed
              fees. This is not equitable in its current form!
            </Text>
            <HalfWidthSmallSubtitle
              color="primary.main"
              pt={[1, 2, 5]}
              fontWeight="bold"
            >
              What do they do with all that money?
            </HalfWidthSmallSubtitle>
            <Text
              fontSize={[1, 2, 1]}
              pb={[2, 0, 0]}
              color="muted"
              fontWeight="normal"
            >
              Dunno. We suspect it lines the pockets of fat cats in stripy
              suits.
            </Text>
          </Container>

          <Box
            width={[1, 1 / 2, 1 / 2]}
            px={[3, 4, 5]}
            sx={{
              display: 'grid',
              gridGap: [1, 2, 2],
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateRows: 'repeat(2, 1fr)',

              '.image': {
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <Box width={1 / 2} alignSelf="end">
              <Img
                className="image"
                fluid={data.UsingChoiceCredit.childImageSharp.fluid}
              />
            </Box>
            <Box width={1 / 2} alignSelf="end">
              <Img
                className="image"
                fluid={data.UsingChoiceContactlessDebit.childImageSharp.fluid}
              />
            </Box>
            {/* credit card payments */}
            <Container>
              <Subtitle
                color="primary.main"
                fontSize={[2, 6, 8]}
                fontWeight="bold"
              >
                2-3%
              </Subtitle>
              <SmallSubtitle color="primary.main" fontSize={[1, 1, -1]}>
                Credit Card
              </SmallSubtitle>
            </Container>

            {/* contactless debit payment option */}
            <Container>
              <Subtitle
                color="primary.main"
                fontSize={[2, 6, 8]}
                fontWeight="bold"
              >
                +1%
              </Subtitle>
              <SmallSubtitle color="primary.main" fontSize={[1, 1, -1]}>
                Contactless debit card*
              </SmallSubtitle>
              <Text color="primary.main" fontSize={[-1, 1, -1]}>
                (i.e. Paywave)
              </Text>
              <SmallSubtitle color="dark" py={2} fontSize={[-1, 1, -1]}>
                *20% contactless usage increase in 2017
              </SmallSubtitle>
            </Container>
          </Box>
        </Flex>
      </Box>
    </SectionBox>
  )
}
