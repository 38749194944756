import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import {
  Container,
  SectionBox,
  SmallSubtitle,
  Subtitle,
  BodyTextLight,
  TextImage,
  SectionHeader,
} from '../base'

function UsingImageWrapper(props: any): React.ReactElement {
  return (
    <Box
      pb={2}
      fontSize={[1, 2, 2]}
      fontFamily="body"
      lineHeight={['1.8rem', 0, 0]}
    >
      <TextImage {...props}>
        <Img {...props} />
      </TextImage>
      {props.text}
    </Box>
  )
}
export const fluidImage4 = graphql`
  fragment fluidImage4 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function DecideProfit(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        ...fluidImage4
      }
      UsingChoicePhone1: file(
        relativePath: { eq: "images/UsingChoicePhone1.png" }
      ) {
        ...fluidImage4
      }
      UsingChoiceKids: file(
        relativePath: { eq: "images/UsingChoiceKids.png" }
      ) {
        ...fluidImage4
      }
      UsingChoiceKiwiLow: file(
        relativePath: { eq: "images/UsingChoiceKiwiLow.png" }
      ) {
        ...fluidImage4
      }
      UsingChoiceGraphWS: file(
        relativePath: { eq: "images/UsingChoiceGraphWS.png" }
      ) {
        ...fluidImage4
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <SectionHeader color="primary.main" mr={[0, 0, '9rem']}>
        When did we decide it was okay to put profit before people and planet?
      </SectionHeader>

      <Flex px={[0, 1, 0]} flexDirection="row" flexWrap="wrap">
        <Container width={[1, 1, 2 / 5]} py={4}>
          <Box>
            <Subtitle color="darkMuted">
              This money could be going to where it is needed more:
            </Subtitle>
          </Box>
        </Container>

        <Container width={[1, 1, 3 / 5]} py={[1, 4, 4]} fontSize={1}>
          <Box
            width={[1, 1, 1]}
            fontSize={[0, 1, 1]}
            pl={[0, 3, 5]}
            pr={[0, 3, 2]}
            sx={{
              display: 'grid',
              grid: '1fr/ 1fr 6fr',
              gridGap: 3,
              gridTemplateColumns: 'repeat(2, 1fr, 8fr)',
              gridTemplateRows: 'repeat(3, 1fr)',

              '.image': {
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <Box alignSelf="center">
              <Img
                className="image"
                fluid={data.UsingChoiceKids.childImageSharp.fluid}
              />
            </Box>
            <BodyTextLight alignSelf="center">
              1 in 3 Kiwi kids live under the income poverty line
            </BodyTextLight>

            <Box alignSelf="center">
              <Img
                className="image"
                fluid={data.UsingChoiceKiwiLow.childImageSharp.fluid}
              />
            </Box>
            <BodyTextLight alignSelf="center">
              80% of our native bird species are endangered
            </BodyTextLight>

            <Box alignSelf="center">
              <Img
                className="image"
                fluid={data.UsingChoiceGraphWS.childImageSharp.fluid}
              />
            </Box>
            <BodyTextLight alignSelf="center">
              We have the highest youth suicide rate in the world
            </BodyTextLight>
          </Box>
        </Container>
      </Flex>

      <SmallSubtitle
        fontSize={-1}
        color="primary.main"
        textAlign="center"
        pt={[3, 4, 5]}
      >
        That’s where Choice steps in
      </SmallSubtitle>
      <Flex justifyContent="center" pt={[2, 4, 2]} mb={[4, 1, 0]}>
        <Box width={[1 / 16, 1 / 16, 1 / 48]}>
          <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
        </Box>
      </Flex>
    </SectionBox>
  )
}
