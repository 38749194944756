import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import {
  Container,
  HalfWidthSmallSubtitle,
  InlineText,
  SectionBox,
  SmallSubtitle,
  SubtitleCenter,
  TextImage,
  SectionHeader,
  BodyTextLight,
} from '../base'

export const fluidImage1 = graphql`
  fragment fluidImage1 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function EnoughEnough(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceQRGraphicAlone: file(
        relativePath: { eq: "images/UsingChoiceQRGraphicAlone.png" }
      ) {
        ...fluidImage1
      }
      HeaderLogo: file(relativePath: { eq: "images/HeaderLogo.png" }) {
        ...fluidImage1
      }
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        ...fluidImage1
      }
      UsingChoiceQRGraphic50percent: file(
        relativePath: { eq: "images/UsingChoiceQRGraphic50percent2.png" }
      ) {
        ...fluidImage1
      }
      UsingChoiceQRGraphicArrow: file(
        relativePath: { eq: "images/UsingChoiceQRGraphicArrow.png" }
      ) {
        ...fluidImage1
      }
    }
  `)
  return (
    <SectionBox bg="primary.main">
      <SectionHeader color="primary.text" mr={[0, 0, '20rem']} pb={0}>
        We’ve decided
      </SectionHeader>
      <SectionHeader color="primary.text" mr={[0, 0, '20rem']}>
        enough is enough
      </SectionHeader>

      <Flex px={[0, 1, 0]} flexDirection="row" flexWrap="wrap">
        <Container width={[1, 1 / 2, 2 / 5]} py={[0, 2, 0]}>
          <Box pt={[0, 3, 5]} fontSize={[0, 0, 1]}>
            <BodyTextLight color="primary.text">
              So&nbsp;
              <TextImage width={[1 / 5, 1 / 5, 1 / 6]} mb={-1}>
                <Img fluid={data.HeaderLogo.childImageSharp.fluid} />
              </TextImage>
              &nbsp;is using&nbsp;
              <InlineText>kiwi ingenuity</InlineText>
              ,&nbsp;
              <InlineText>aroha</InlineText>
              &nbsp;and&nbsp;
              <InlineText>people power &nbsp;</InlineText>
              to build a better way to pay that enables you to send that money
              to where it is needed most.
            </BodyTextLight>
            <BodyTextLight color="primary.text" pt={4}>
              And the fees that Kiwis pay are reduced, with half going And the
              fees that Kiwis pay are reduced, with half going directly to the
              Kiwi's that need it most.
            </BodyTextLight>
            <HalfWidthSmallSubtitle
              color="secondary.main"
              fontSize={[2, 4, 6]}
              lineHeight={[1, 2, '3rem']}
            >
              It’s a win-win-win for everyone.
            </HalfWidthSmallSubtitle>
          </Box>
        </Container>

        <Box
          width={[1, 1 / 2, 3 / 5]}
          justifyContent="start"
          pl={[0, 3, 5]}
          pr={[0, 3, 2]}
          sx={{
            display: 'grid',
            gridGap: [1, 2, 2],
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',

            '.image': {
              width: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            },
          }}
        >
          <Box width={1 / 2} alignSelf="end" justifySelf="center">
            <Img
              className="image"
              fluid={data.UsingChoiceQRGraphicAlone.childImageSharp.fluid}
            />
          </Box>
          <Box
            width={4 / 7}
            pt={[4, 5, 0]}
            alignSelf="center"
            justifySelf="center"
          >
            <Img
              className="image"
              fluid={data.UsingChoiceQRGraphicArrow.childImageSharp.fluid}
            />
          </Box>
          <Box alignSelf="end">
            <Img
              className="image"
              fluid={data.UsingChoiceQRGraphic50percent.childImageSharp.fluid}
            />
          </Box>
          <Box width={1}>
            <SmallSubtitle fontSize={[-1, -1, 1]} textAlign="center">
              Choice transaction fee
            </SmallSubtitle>
            <BodyTextLight
              fontSize={[-2, -2, -1]}
              color="primary.text"
              textAlign="center"
              lineHeight={['1rem', '1rem', '1.2rem']}
            >
              A flat rate, reduced transaction fee that&#39;s the same for
              everyone.
            </BodyTextLight>
          </Box>
          <Box /> {/* spacer */}
          <Box>
            <SmallSubtitle fontSize={[-1, -1, 1]} textAlign="center">
              50% goes to charity
            </SmallSubtitle>
            <BodyTextLight
              fontSize={[-2, -2, -1]}
              color="primary.text"
              textAlign="center"
              lineHeight={['1rem', '1rem', '1.2rem']}
            >
              Choice redirects half of the transaction fee that Kiwi businesses
              pay Choice to your nominated New Zealand charity. The rest stays
              in Aotearoa to grow our mahi.
            </BodyTextLight>
          </Box>
        </Box>
      </Flex>

      <SubtitleCenter fontSize={[0, 1, 2]} pb={[2, 3, 0]} pt={[3, 2, 0]}>
        Start paying the choice way
      </SubtitleCenter>
      <Flex justifyContent="center" py={[2, 2, 2]} mb={[3, 0, 0]}>
        <Box width={[1 / 16, 1 / 36, 1 / 64]}>
          <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
        </Box>
      </Flex>
    </SectionBox>
  )
}
