import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box } from 'rebass/styled-components'
import {
  Container,
  SmallSubtitle,
  SectionBox,
  TextImage,
  AccessButton,
  SectionHeader,
  BodyTextLight,
} from '../base'
import CustomerJoinLink from '../CustomerJoinLink'
import { Flex } from 'rebass'

export function JoinMovement(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HomeLogoInlineNew: file(
        relativePath: { eq: "images/HomeLogoInlineNew.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <Container width={[1, 1, 1 / 2]} px={[0, 3, 0]}>
        <SectionHeader color="primary.main">Join the movement</SectionHeader>
        <SmallSubtitle color="darkMuted" pr={[0, 0, 6]}>
          It's free and takes two minutes to set up.
        </SmallSubtitle>
      </Container>

      <Container
        width={[1, 1, 3 / 5]}
        p={[1, 2, 4]}
        mt={[4, 0, '-10em']}
        ml={[0, 0, '25rem']}
      >
        <Box
          width={[1 / 9, 1 / 15, 1 / 16]}
          style={{ alignSelf: 'center' }}
          my={2}
        >
          <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
        </Box>
        <Flex justifyContent="center">
          <AccessButton
            width={[4 / 5, 1 / 2, 3 / 5]}
            mt={[2, 2, 0]}
            mb={[1, 3, 3]}
            fontSize={[1, 2, 2]}
            fontWeight="semibold"
            justifyContent="center"
          >
            <CustomerJoinLink />
          </AccessButton>
        </Flex>
        <Box
          width={[4 / 5, 3 / 5, 3 / 5]}
          style={{ alignSelf: 'center' }}
          mt={[3, 1, 1]}
        >
          <BodyTextLight>Put people and planet before profit.</BodyTextLight>
          <BodyTextLight>
            Pay with&nbsp;
            <TextImage width={[1 / 4, 1 / 6, 1 / 6]}>
              <Img fluid={data.HomeLogoInlineNew.childImageSharp.fluid} />
            </TextImage>
          </BodyTextLight>
        </Box>
      </Container>
    </SectionBox>
  )
}
