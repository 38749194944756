import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  Container,
  SectionBox,
  HalfWidthSmallSubtitleBold,
  AccessButton,
  SectionHeader,
} from '../base'
import CustomerJoinLink from '../CustomerJoinLink'

export function UsingChoiceFree(props): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UsingChoicePhone1: file(
        relativePath: { eq: "images/UsingChoicePhone1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <UsingChoiceHeader />
      <SubtitleInsteadOfPaying />

      <Box ml={[0, 0, '25rem']}>
        <Container mt={[0, 0, 5]} ml={[0, 0, '-12rem']}>
          <Box alignSelf="start" pt={1} width={[0, 0, 2 / 12]}>
            <Img fluid={data.UsingChoicePhone1.childImageSharp.fluid} />
          </Box>
        </Container>

        <ThreeSteps />

        <Container alignItems="center" justifyContent="center">
          <Box width={[4 / 5, 3 / 5, 1 / 5]} ml={[0, 6, '-27rem']}>
            <Box
              width={[1 / 10, 1 / 20, 1 / 4]}
              mb={2}
              pt={[4, 4, 6]}
              ml={['6rem', '6.5rem', '-8rem']}
              mt={[0, 0, '-8rem']}
            >
              <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
            </Box>

            <Box ml={[0, 0, '-16rem']}>
              <Flex justifyContent="start">
                <AccessButton width={[1, 3 / 5, 4 / 5]}>
                  <CustomerJoinLink textColor="primary.text" />
                </AccessButton>
              </Flex>
            </Box>
          </Box>
        </Container>
      </Box>
    </SectionBox>
  )
}

function UsingChoiceHeader(): React.ReactElement {
  return (
    <SectionHeader color="darkMuted">
      Using&nbsp;
      <Text sx={{ display: 'inline-block' }} color="primary.main">
        Choice&nbsp;
      </Text>
      is&nbsp;
      <Text sx={{ display: 'inline-block' }} color="primary.main">
        free & easy
      </Text>
    </SectionHeader>
  )
}

function SubtitleInsteadOfPaying(): React.ReactElement {
  return (
    <Box
      pt={[2, 4, 2]}
      width={[1, 1, 4 / 8]}
      fontSize={[1, 2, 1]}
      fontFamily="body"
      fontWeight="normal"
      color="muted"
      lineHeight={[1, 1, 1.2]}
    >
      <Text pb={[0, 0, 3]}>
        Instead of paying at stores with your credit or debit card, open the
        Choice app on your phone and scan the Choice QR code at checkout.
      </Text>
    </Box>
  )
}
function ThreeSteps(): React.ReactElement {
  return (
    <Container
      width={[1, 1, 4 / 5]}
      ml={[0, 0, 2]}
      mt={[0, 0, '-18rem']}
      pb={[0, 0, 1]}
    >
      <Box
        px={[0, 2, 5]}
        pr={[0, 4, 2]}
        fontFamily="body"
        fontSize={[0, 2, 1]}
        lineHeight={1.2}
        fontWeight="light"
        color="muted"
      >
        <HalfWidthSmallSubtitleBold>
          1. Connect your bank account
        </HalfWidthSmallSubtitleBold>
        <Text>
          The app will take you to a &nbsp;
          <Text sx={{ display: 'inline-block' }} fontWeight="bold">
            secure and approved&nbsp;
          </Text>
          authentication screen provided by your bank. Once you connect with
          your account, you are authorised to pay at our participating Kiwi
          businesses.
        </Text>
        <HalfWidthSmallSubtitleBold>
          2. Scan at the counter
        </HalfWidthSmallSubtitleBold>
        <Text sx={{ display: 'inline-block' }} fontWeight="bold">
          Now to shop with impact!&nbsp;
        </Text>
        <Text>
          Scan the ChoiceQR code when you get to the counter. Securely pay
          straight from your phone.
        </Text>
        <HalfWidthSmallSubtitleBold pt={[3, 0, 4]}>
          3. Track your impact
        </HalfWidthSmallSubtitleBold>
        <Text>
          We redirect 50% of the transaction fee that Kiwi businesses pay Choice
          to your nominated New Zealand charity.
        </Text>
        <Text sx={{ display: 'inline-block' }} fontWeight="bold">
          Track your impact in the Choice App.
        </Text>
      </Box>
    </Container>
  )
}
