import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  Container,
  SectionBox,
  SmallSubtitle,
  Subtitle,
  AccessButtonReverse,
  SectionHeader,
} from '../base'
import CustomerJoinLink from '../CustomerJoinLink'

export function NeedHelp(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceHeart: file(
        relativePath: { eq: "images/UsingChoiceHeart.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hand: file(relativePath: { eq: "images/hand.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionBox bg="primary.main">
      <Box>
        <Container width={[1, 1, 3 / 7]}>
          <SectionHeader color="primary.text">
            New Zealand, we need your help!
          </SectionHeader>
        </Container>

        <Container
          pl={[0, 2, 0]}
          pt={[4, 4, 0]}
          width={[1, 1, 4 / 10]}
          ml={[0, 0, '34rem']}
          mt={[0, 0, '-12rem']}
          alignItems="center"
        >
          <Box
            pb={[4]}
            pt={[1, 0, 0]}
            width={[1 / 4, 1 / 7, 1 / 8]}
            alignSelf="center"
          >
            <Img fluid={data.UsingChoiceHeart.childImageSharp.fluid} />
          </Box>

          <SmallSubtitle fontSize={[4, 4, 2]} fontWeight="bold" pb={2}>
            Together, we can redirect a mighty big chunk of this to charity each
            year:
          </SmallSubtitle>
          <Subtitle
            color="primary.text"
            py={1}
            fontSize={[6, 10, 10]}
            fontWeight="black"
          >
            $461 million
          </Subtitle>
          <Text
            color="white"
            fontSize={-2}
            fontFamily="body"
            fontWeight="regular"
            py={2}
          >
            Total amount of electronic card transaction fees charged to kiwi
            merchants in 2015*
          </Text>
          <Box marginY={3}>
            <Flex justifyContent="center">
              <AccessButtonReverse
                bg="secondary.main"
                width={1}
                fontSize={[1, 1, 1]}
                justifyContent="center"
              >
                <CustomerJoinLink textColor="primary.main" />
              </AccessButtonReverse>
            </Flex>
          </Box>
        </Container>
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mr={[0, 0, 6]}
      >
        <SmallSubtitle fontSize={1} pt={[2, 4, 4]}>
          And it's really quite easy!
        </SmallSubtitle>
        <Box
          width={[1 / 16, 1 / 16, 1 / 36]}
          // ml={['9rem', '21rem', '40rem']}
          py={[2, 3, 3]}
        >
          <Img fluid={data.hand.childImageSharp.fluid} />
        </Box>
      </Flex>
    </SectionBox>
  )
}
